<template>
	<div class="media-video-container">
		<!-- 默认纯视频 -->
		<div v-if="props.columnsItem.Css.includes('default_video')" :class="props.columnsItem.Css" class="media-video default_video">
			<!-- 视频播放器 -->
			<div class="video-player-container" :class="fullPolyvEvent ? '' : 'default-video-player-container'">
				<div :id="props.columnsItem.Title" class="flex-center-center video-player-box"></div>
				<!-- <div class="video-player-title flex-center-center">
					{{ props.columnsItem.Title }}
				</div> -->
			</div>
		</div>
		<!-- 带背景的视频 -->
		<div v-else class="media-video-default-container">
			<!-- 背景图 -->
			<div class="event-video-bg">
				<img class="event-video-bg-img" src="@/assets/images/event-video-bg.png" alt="" />
			</div>

			<!-- 视频内容 -->
			<div class="media-video-default">
				<div class="media-video-default-content">
					<div class="flex-center-center">
						<!-- 视频左背景 -->
						<div class="video-player-bg-left"></div>
						<!-- 视频播放器 -->
						<div class="video-player-container">
							<div :id="props.columnsItem.Title"></div>
						</div>
						<!-- 视频右背景 -->
						<div class="video-player-bg-right"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { reactive, onMounted, onBeforeUnmount, computed } from 'vue';
import { videoParamApi } from '@/http/api/course';
import { store } from '@/store';
const fullPolyvEvent = computed(() => store.state.fullPolyvEvent);
const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
	ChannelId: {
		type: String,
		default: '',
	},
});
const data = reactive({
	player: null,
});
onMounted(() => {
	console.log(props.columnsItem);
	getVideoParamApi();
});
//  -------------------请求接口开始---------------------
// 视频加密参数
const getVideoParamApi = () => {
	const params = {
		vid: JSON.parse(props.columnsItem.Data).Vid,
	};
	videoParamApi(params)
		.then((res: any) => {
			const option = {
				width: fullPolyvEvent ? '100%' : '440',
				height: fullPolyvEvent ? '' : '300',
				wrap: '#' + props.columnsItem.Title,
				vid: JSON.parse(props.columnsItem.Data).Vid,
				autoplay: false,
				// loading_bg_img: options.CourseCover,
				playsafe: res.Playsafe,
				ts: res.Timestamp,
				sign: res.Sign,
				// watchStartTime:,//播放开始时间，表示视频从第几秒开始播放，参数值需小于视频时长
			};

			data.player = (window as any).polyvPlayer(option);
		})
		.catch((err: any) => {
			console.log(err);
		});
};
// ---------------------------------请求接口结束----------------------
// 离开页面前
onBeforeUnmount(() => {
	if (data.player) {
		(data.player as any).destroy();
	}
});
window.onbeforeunload = function leavePage() {
	if (data.player) {
		(data.player as any).destroy();
	}
};
</script>
<style scoped lang="scss">
.media-video-container {
	width: 100%;
	.default_video {
		width: 100%;
		height: 560px;
		.default-video-player-container {
			background: #ffffff;
			box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.18);
		}
		.video-player-container {
			width: 100%;
			height: 100%;
			border-radius: 2px 2px 8px 8px;
			.video-player-box {
				width: 100%;
				height: 100%;
			}
			.video-player-title {
				font-size: 16px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #000000;
				line-height: 22px;
				padding: 20px 0;
				box-sizing: border-box;
			}
		}
	}
	.media-video-default-container {
		width: 100%;
		position: relative;
		padding: 60px 0 0px 0;
		box-sizing: border-box;
		.event-video-bg {
			position: absolute;
			left: 0;
			top: 0;
			.event-video-bg-img {
				width: 526px;
				height: auto;
			}
		}
		.media-video-default {
			width: 80%;
			max-width: 840px;
			margin: 0 auto;
			position: relative;
			.media-video-default-content {
				width: 100%;
				height: 500px;
				position: relative;
				.video-player-bg-left {
					width: 236px;
					height: 350px;
					background: #1a5fea;
					position: absolute;
					bottom: 0;
					left: 0;
				}
				.video-player-container {
					z-index: 100;
					width: 800px;
					height: 480px;
					position: absolute;
					left: 20px;
					bottom: 20px;
					cursor: pointer;
					.event-video-pause-icon {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);

						img {
							width: 40px;
							height: auto;
						}
					}
				}
				.video-player-bg-right {
					width: 236px;
					height: 260px;
					background: #1a5fea;
					position: absolute;
					top: 0;
					right: 0;
				}
			}
		}
	}
}
</style>
