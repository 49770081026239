<template>
	<div class="swiper-section-wrap" :class="[{ 'flex-center-start': columnsItem.SameLine }, columnsItem.Css]">
		<!-- 标题 -->
		<div v-if="!props.columnsItem.HideHeader">
			{{ props.columnsItem.Title }}
		</div>
		<div class="flex-center-between swiper-section">
			<div class="baize">
				<img :src="getImageUrl(baizeSrc)" alt="" class="baize-icon" @click="toBaize" />
			</div>
			<!-- 分类模块 -->
			<div class="classify-container">
				<LeftClassify></LeftClassify>
			</div>
			<!-- 轮播图 -->
			<div class="swiper-container">
				<!-- :interval="3000" -->
				<el-carousel height="325" :autoplay="true" indicator-position="none" class="my-banner-carousel">
					<el-carousel-item v-for="(item, index) in JSON.parse(props.columnsItem.Data).Items" :key="index">
						<img :src="item.ImageUrl" alt="" @click="jumpRoute(item)" class="swiper-img" />
					</el-carousel-item>
				</el-carousel>
			</div>
			<!-- 登录注册 -->
			<LoginModule></LoginModule>
		</div>
		<!-- 视频 -->
		<div v-if="data.videoShow" class="video-container flex-center-center">
			<div class="video-content">
				<div class="close" @click="data.videoShow = false">
					<img class="close-icon" src="@/assets/images/close-black-icon.png" alt="" />
				</div>
				<div class="base-video-container">
					<BaseVideo :videoPoster="data.videoPoster" :videoUrl="data.videoUrl"></BaseVideo>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import BaseVideo from '../baseVideo/BaseVideo.vue';
import { store } from '@/store';

const baizeSrc = ref('baize3.gif');
const router = useRouter();
const props = defineProps({
	columnsItem: {
		type: Object,
		default: {},
	},
});
// console.log(props.columnsItem)
const data = reactive({
	screenHeight: '',
	videoShow: false, //是否显示视频
	videoUrl: '', //视频路由
	videoPoster: '', //视频封面图
});

onMounted(() => {
	// 初始化高度
	getWidth();
	// 屏幕变化的时候计算高度
	window.onresize = () => {
		getWidth;
	};
});
// 获取swiper 宽度
const getWidth = () => {
	if (document.documentElement.clientWidth * 1 > 1440) {
		data.screenHeight = 370 + 'px';
	} else {
		data.screenHeight = 370 + 'px';
		// (document.documentElement.clientWidth * 370) / 1440 + "px";
	}
};
// 获取图片路径
const getImageUrl = (name: string) => {
	return new URL(`../../assets/images/baize/${name}`, import.meta.url).href;
};

const toggleBaize = () => {
	// baizeSrc.value = baizeSrc.value === 'baize2.png' ? 'baize1.png' : 'baize2.png';
};

const toBaize = () => {
	window.open(store.state.baizeUrl, '_blank');
};

// 跳转路径
const jumpRoute = (item: any) => {
	const path = item.Target;
	if (judgmentFile(path)) {
		data.videoUrl = path;
		data.videoPoster = item.ImageUrl;
		data.videoShow = true;
	} else {
		if (item.TargetType == 'Url') {
			window.location.href = path;
		} else if (item.TargetType == 'Local') {
			window.location.href = path;
			// router.push({
			//   path: item.Target,
			// });
		}
	}
};

// 判断是否是视频
const judgmentFile = (picUrl: any) => {
	let filename = picUrl; //文件路径地址
	let index1 = filename.lastIndexOf('.');
	let index2 = filename.length;
	let postf = filename.substring(index1, index2); //获取文bai件后缀名地址
	//判断文件后缀名是否等于视频文件的后缀名
	if (postf === '.avi' || postf === '.mp4' || postf === '.rmvb' || postf === '.ogg') {
		return true;
	} else {
		return false;
	}
};
</script>
<style lang="scss" scoped>
.swiper-section-wrap {
	box-sizing: border-box;
	background: linear-gradient(180deg, #d2ebff 0%, #fafafa 100%);
	padding-top: 20px;
	padding-bottom: 20px;

	.baize {
		position: absolute;
		top: 60px;
		left: 100%;
		cursor: pointer;
		.baize-icon {
			width: 68px;
			height: auto;
		}
	}
	.swiper-section {
		margin-top: 20px;
		margin-bottom: 20px;
		max-width: 1200px;
		margin: 0 auto;
		position: relative;
		.classify-container {
			width: 225px;
			min-height: 325px;
			background: #fff;
			border-radius: 8px;
		}
		.swiper-container {
			width: 750px;
			height: 325px;
			display: flex;
			align-items: center;
			border-radius: 5px;
			.swiper-img {
				border-radius: 5px;
			}
		}
	}

	.video-container {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.4);
		z-index: 1000;
		.video-content {
			width: 80%;
			max-width: 880px;
			padding: 40px 0;
			box-sizing: border-box;
			position: relative;
			.close {
				position: absolute;
				top: 0px;
				right: -25px;
				.close-icon {
					width: 24px;
					height: auto;
					// margin-right: -20px;
					// margin-bottom: 10px;
					cursor: pointer;
				}
			}
			.base-video-container {
				width: 880px;
				height: 495px;
				background: rgba(0, 0, 0);
			}
		}
	}
}
</style>

<style lang="scss">
.my-banner-carousel {
	width: 778px;
	height: 100%;
	border-radius: 5px;
	.el-carousel__container {
		width: 100%;
		height: 100%;
		border-radius: 5px;
		.el-carousel__item {
			border-radius: 5px;
			.el-carousel__mask {
				background-color: #000000;
				opacity: 0.5;
			}
			img {
				display: block;
				width: 100%;
				height: auto;
				margin: 0;
				border-radius: 5px;
			}
		}
	}
}
</style>
