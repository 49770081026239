import request from '../request';
import qs from 'qs';
// 保存课程完成情况
export function PostCourseProgressApi(data: object) {
	return request({
		url: '/Completion/Course/' + data.CourseId,
		method: 'post',
		// baseURL:'http://49.4.122.4:2103/api',
		// baseURL:'/api',
		data,
	});
}
// 课程分类列表
export function GetUserCourseApi(data: object) {
	return request({
		url: '/Completion/Course/Paging?' + qs.stringify(data, { arrayFormat: 'repeat' }),
		method: 'get',
		data,
	});
}

// 开始课程模块会话
export function PostStartStudy(data: object) {
	return request({
		url:
			'/Completion/Course/' +
			data.CourseId +
			'/Module/' +
			data.ModuleType +
			'/' +
			data.ModuleId +
			'/Session/Start?Origin=' +
			data.Origin,
		method: 'post',
		data,
	});
}
// 进程课程模块会话
export function PostProcessStudy(data: object) {
	return request({
		url: '/Completion/Course/' + data.CourseId + '/Module/' + data.ModuleType + '/' + data.ModuleId + '/Session/Process',
		method: 'post',
		data,
	});
}
// 进程课程模块会话
export function PostEndStudy(data: object) {
	return request({
		url: '/Completion/Course/' + data.CourseId + '/Module/' + data.ModuleType + '/' + data.ModuleId + '/Session/End',
		method: 'post',
		data,
	});
}

// 分页显示课程模块完成情况
export function getCompleteModules(CourseId: number) {
	return request({
		url: '/Completion/Course/' + CourseId + '/Module/Paging?PageNum=1&PageSize=5000',
		method: 'get',
	});
}

// 是否已生成证书
export function getCertificateState(data: any) {
	return request({
		url: '/Completion/Certificate/State?CourseId=' + data.CourseId,
		method: 'get',
	});
}

// 生成证书
export function setCourseCertificate(data: any) {
	return request({
		url: '/Completion/Generate/Certificate?' + qs.stringify(data, { arrayFormat: 'repeat' }),
		method: 'get',
	});
}
