<template>
	<div
		class="nav-section width-225 border-radius-8"
		@mouseleave="reset()"
		:class="{
			'border-radius-8008': data.navSlideShow,
			'max-classify-height': isMaxClassifyHeight,
		}"
	>
		<div class="flex-start-between" v-if="firstNavList.length" @mouseenter="clickMore" @mouseleave="clickMore">
			<div class="nav-left-list">
				<div
					class="nav-left-item"
					v-for="(item, index) in firstNavList"
					:key="index"
					@mouseenter.stop="clickFirstNav(index)"
					@click="toFirstNav(item)"
					:class="{ 'current-nav-left-item': data.currentFirstIndex === index }"
				>
					<div class="flex-1">
						<div>
							<a :title="item.Name" class="letter-space-7">{{ item.Name }}</a>
						</div>
						<div class="nav-second-list flex-1 oneDot">
							<template v-for="(childItem, childIndex) in item.childSortList" :key="childIndex">
								<div class="nav-second-item">
									<a :title="childItem.Name" @click.stop="toDetailByClassify(childItem)">{{ childItem.Name }}</a>
								</div>
							</template>
						</div>
					</div>
					<div class="right-icon" v-if="item.Id && item.childSortList.length">
						<img src="@/assets/images/arrow-right-icon.png" alt="" />
					</div>
				</div>
			</div>
			<div
				class="nav-slide-area left-225 border-radius-0880 width-770"
				v-show="data.navSlideShow"
				:style="{ minHeight: leftNavHeight + 'px' }"
			>
				<SecondNav :list="data.sortList" :current-channel-id="data.ChannelId"></SecondNav>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { reactive, watch } from 'vue';
import { slice } from 'lodash';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();
const router = useRouter();
const limitFirstClassifyCount = 8;
const isMaxClassifyHeight = computed(() => leftNavHeight.value > window.screen.availHeight - 90 - 100);

const data = reactive<any>({
	isShow: true,
	navSlideShow: false,
	sortList: [],
	ChannelId: '',
	currentFirstIndex: -1,
	baseData: computed(() => store.state.baseData),
	expandNav: true, //是否折叠左侧分类
});

const allCourseCategorys = computed(() => store.state.baseData.CourseCategorys || []);
const channelsData = computed(() => {
	const channels = store.state.baseData.Channels || [];

	// 处理一级分类下的二级分类列表
	const result = channels.map((v: any) => {
		let childSortList = filterFirstClassifyCourse(v.Id);
		// 处理学历下二级分类下新增“单科认证”跳转外部链接
		if (v.Id === 'education') {
			const obj = {
				ChannelId: '',
				Id: '',
				Index: '',
				Level: 1,
				Name: '单科认证',
				OrderBy: 1,
				OrganizationId: null,
				ParentId: null,
				Link: 'https://le.ouchn.cn/Event/dkrz',
			};
			childSortList.splice(0, 0, obj);
		}

		return { ...v, childSortList: slice(childSortList, 0, 2) };
	});

	if (!result.some((v: any) => v.Name === '学分银行')) {
		result.push({ Name: '学分银行', path: 'https://www.ncb.edu.cn/', Id: '' });
	}
	// if (!result.some((v: any) => v.Name === '北京国家开放大学教育基金会')) {
	// 	result.push({ Name: '北京国家开放大学教育基金会', path: 'http://oucef.ouchn.edu.cn/', Id: '' });
	// }

	return result;
});

const firstNavList = computed(() => {
	const channels = channelsData.value;
	return data.expandNav ? slice(channels, 0, limitFirstClassifyCount) : channelsData.value;
});

const leftNavHeight = computed(() => {
	const height = 37 * firstNavList.value.length + 14.5 * 2;
	return height;
});

watch(
	() => data.baseData,
	() => {
		reset();
	},
);

// 重置
const reset = () => {
	data.currentFirstIndex = -1;
	data.navSlideShow = false;
	data.expandNav = true;
};

// 点击一级分类
const clickFirstNav = (index: number) => {
	if (data.currentFirstIndex === index) return;
	data.currentFirstIndex = index;
	data.ChannelId = firstNavList.value[index].Id;
	if (data.ChannelId) {
		if (firstNavList.value[index].childSortList.length) {
			data.navSlideShow = true;
			getChildSortList(data.ChannelId);
		} else {
			data.navSlideShow = false;
		}
	} else {
		data.navSlideShow = false;
	}
};

// 更多
const clickMore = () => {
	data.expandNav = !data.expandNav;
};

// 跳转分类筛选页
const toDetailByClassify = (item: any) => {
	const Link = item.Link;
	if (Link) {
		window.open(Link, '_blank');
		return;
	}
	if (!data.ChannelId) return;
	let ParentId, ChildId, routeData: any;

	if (item.Level === 1) {
		ParentId = item.Index.split(',')[1];
		routeData = router.resolve({ path: '/screenCourseList', query: { ChannelId: data.ChannelId, ParentId } });
	}

	if (item.Level === 2) {
		ChildId = item.Index.split(',')[2];
		ParentId = item.Index.split(',')[1];
		routeData = router.resolve({ path: '/screenCourseList', query: { ChannelId: data.ChannelId, ParentId, ChildId } });
	}

	window.open(routeData.href, '_blank');
};

// 跳转一级分类页
const toFirstNav = (item: any) => {
	if (item.Alias) {
		const routeData = router.resolve({ path: '/' + item.Alias });
		window.open(routeData.href, '_blank');
	}

	if (item.path) {
		window.open(item.path);
	}
};

// 筛选一级分类
const filterFirstClassifyCourse = (Id: string) => {
	return allCourseCategorys.value.filter((item: any) => item.Level === 1 && item.ChannelId === Id);
};

// 筛选二级级分类
const filterSecondClassifyCourse = (Id: string) => {
	return allCourseCategorys.value.filter((item: any) => item.Level === 2 && item.ParentId === Id);
};

// 获取课程二级分类列表
const getChildSortList = (Id: string) => {
	data.sortList = filterFirstClassifyCourse(Id);
	data.sortList = data.sortList.map((v: any) => {
		return { ...v, childSortList: filterSecondClassifyCourse(v.Id) };
	});
};

reset();
</script>
<style lang="scss" scoped>
/* 设置滚动条的样式 */
::-webkit-scrollbar {
	width: 0px;
}
.letter-space-7 {
	letter-spacing: 7px;
}
.width-225 {
	width: 225px;
}
.width-120 {
	width: 120px;
}

.width-770 {
	width: 770px;
}

.width-760 {
	width: 760px;
}

.left-120 {
	left: 120px;
}

.left-225 {
	left: 225px;
}

.border-radius-0440 {
	border-radius: 0 4px 4px 0;
}

.border-radius-0880 {
	border-radius: 0px 8px 8px 0;
}

.border-radius-0444 {
	border-radius: 0 4px 4px 4px;
}

.border-radius-0004 {
	border-radius: 0 0 0 4px;
}

.border-radius-8 {
	border-radius: 8px;
}

.border-radius-8008 {
	border-radius: 8px 0px 0px 8px;
}

.border-right-none {
	border-right: none;
}

.max-classify-height {
	max-height: calc(100vh - 90px - 100px);
	overflow-y: auto;
}

.nav-section {
	position: absolute;
	background: #ffffff;
	min-height: 325px;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.13);
	border: 1px solid #dddddd;
	transition: all 0.2s linear;

	.nav-left-list {
		display: flex;
		flex-direction: column;
		position: relative;
		padding: 14.5px 0;
		width: 100%;
		box-sizing: border-box;
		transition: all 0.1s linear;

		.nav-left-item {
			font-size: 13px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #000000;
			line-height: 13px;
			cursor: pointer;
			min-height: 37px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 18px;
			transition: all 0.3s linear;
			font-weight: 700;

			.nav-second-list {
				display: flex;
				flex-direction: row;
				justify-content: start;
				color: #999;
				font-size: 12px;
				margin-left: 10px;
				font-weight: normal;
				.nav-second-item {
					&:hover {
						color: #3c6bff;
					}
					&:first-of-type {
						margin-right: 10px;
					}
				}
			}

			.right-icon {
				img {
					width: 9px;
					height: 9px;
				}
			}
		}

		.current-nav-left-item {
			background: rgba(88, 139, 255, 0.12);
			border-radius: 3px;
			height: 37px;
			font-size: 13px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			position: relative;
			transition: all 0.3s linear;
			font-weight: 700;

			&::before {
				content: '';
				position: absolute;
				left: 0;
				width: 2px;
				height: 37px;
				z-index: 1;
				background: #588bff;
				transition: all 0.3s linear;
			}
		}
	}

	.nav-slide-area {
		z-index: 9999;
		background: #fff;
		position: absolute;
		top: -1px;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.13);
		border: 1px solid #dddddd;
		border-left: none;
		max-height: calc(100vh - 90px - 100px);
		overflow-y: auto;
		transition: all 0.3s linear;
		.dotted-line {
			position: absolute;
			left: 0;
			height: 100%;
			border-right: 1px dotted #eee;
		}

		.nav-second-area {
			display: flex;
			box-sizing: border-box;
			padding: 14.5px 20px 14.5px 0;

			.nav-second-list {
				display: flex;
				flex-direction: column;
			}
			.nav-second-item {
				display: flex;
				align-items: center;
				overflow: hidden;
				font-size: 13px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #000000;
				line-height: 13px;
				cursor: pointer;
				height: 37px;
				.nav-second-left {
					min-width: 85px;
					padding-left: 15px;
					box-sizing: border-box;
					height: 100%;
					display: flex;
					align-items: center;
					font-weight: 700;
				}
			}
		}
	}
}
</style>
