<template>
	<div class="affix-area">
		<el-popover
			popper-class="transparent-popover"
			placement="left"
			:teleported="false"
			:width="168"
			:show-arrow="false"
			:show-after="300"
		>
			<template #reference>
				<div class="affix_btn flex-column-center" @mouseenter="showBtn(0)" @mouseleave="clear">
					<img src="@/assets/images/affix/svg/phone-active.svg" v-if="data.current === 0" />
					<img src="@/assets/images/affix/svg/phone.svg" v-else />
				</div>
			</template>
			<template #default>
				<Download></Download>
			</template>
		</el-popover>
		<el-popover
			placement="left"
			:teleported="false"
			:width="260"
			:show-arrow="false"
			:show-after="300"
			popper-class="transparent-popover"
		>
			<template #reference>
				<div class="affix_btn flex-column-center" @mouseenter="showBtn(1)" @mouseleave="clear">
					<img src="@/assets/images/affix/svg/code-active.svg" v-if="data.current === 1" />
					<img src="@/assets/images/affix/svg/code.svg" v-else />
				</div>
			</template>
			<template #default>
				<Attention></Attention>
			</template>
		</el-popover>
		<el-popover
			popper-class="transparent-popover"
			placement="left"
			:teleported="false"
			:width="204"
			:show-arrow="false"
			:show-after="300"
		>
			<template #reference>
				<div class="affix_btn flex-column-center" @mouseenter="showBtn(2)" @mouseleave="clear" @click="contactService">
					<a href="https://ouconline.s4.udesk.cn/im_client/?web_plugin_id=35122&group_id=14434" target="_blank">
						<img src="@/assets/images/affix/svg/contact-active.svg" v-if="data.current === 2" />
						<img src="@/assets/images/affix/svg/contact.svg" v-else />
					</a>
				</div>
			</template>
			<template #default>
				<Contact></Contact>
			</template>
		</el-popover>
		<el-popover
			popper-class="transparent-popover"
			placement="left"
			:teleported="false"
			:show-arrow="false"
			:show-after="300"
			:width="144"
		>
			<template #reference>
				<div class="affix_btn flex-column-center" @mouseenter="showBtn(3)" @mouseleave="clear" @click="scrollTop">
					<img src="@/assets/images/affix/svg/gotop-active.svg" v-if="data.current === 3" />
					<img src="@/assets/images/affix/svg/gotop.svg" v-else />
				</div>
			</template>
			<template #default>
				<GoTop></GoTop>
			</template>
		</el-popover>
		<!-- <div class="flex-column-center" @click="toBaize">
			<img src="@/assets/images/affix/baize.png" class="baize-icon" />
		</div> -->
		<!-- <Vue3DraggableResizable
			:min-width="width"
			:min-height="height"
			:x="x"
			:y="y"
			:parent="false"
			:draggable="true"
			:resizable="false"
			:prevent-deactivation="true"
			class="chat-people"
		> -->
		<!-- <div @mouseenter="togglePeople(false)" @mouseleave="togglePeople(true)" v-if="isPro">
			<img v-if="data.peopleShow" src="@/assets/images/affix/people_default.png" alt="" class="people-icon" />
			<template v-else
				><el-popover placement="left" :width="200" content="您好！我是智能小泽学习助手" :show-after="200">
					<template #reference>
						<img src="@/assets/images/affix/people.png" alt="" class="people-icon" @click.native="peopleClick" />
					</template> </el-popover
			></template>
		</div> -->

		<!-- </Vue3DraggableResizable> -->
	</div>

	<!-- <ChatGptQuestionAnswer :is-show="data.chatShow" @hide="data.chatShow = false"></ChatGptQuestionAnswer> -->

	<EmbedComponent
		v-if="data.showChat"
		:buttonSrc="buttonImg"
		src="https://llm.ouchn.cn/?tid=zsjypt&embed=1"
		:greetings="data.greetings"
		:quickerQuestions="data.quickerQuestions"
	/>
</template>
<script lang="ts" setup>
import { reactive, computed } from 'vue';
import { scrollTop, contactService } from '@/utils/event/general';
import EmbedComponent from '@/components/embed/index.vue';
import buttonImg from '@/assets/images/baize/course-detail.gif';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

// 判断移动端还是pc端
const flagRouter = JSON.parse(sessionStorage.getItem('flag') || '0');
const router = useRouter();
const store = useStore();
const isPro = window.location.host === 'web-le.ouc-online.com.cn' || window.location.host === 'localhost:4200';
const data: any = reactive({
	current: -1,
	chatShow: false,
	peopleShow: true,
	showChat: computed(() => !flagRouter && store.state.chatShow),
	greetings: '',
	quickerQuestions: [],
});
const showBtn = (type: number) => (data.current = type);
const clear = () => (data.current = -1);
const togglePeople = (value: boolean) => {
	data.peopleShow = value;
};

const peopleClick = () => {
	window.open('http://202.205.160.9:13000/');
	// data.chatShow = !data.chatShow
};

const toBaize = () => {
	const routerData = router.resolve({
		path: '/digitalHuman',
	});
	window.open(routerData.href, '_blank');
};
</script>
<style lang="scss" scoped>
.affix-area {
	position: fixed;
	top: 35vh;
	right: 0;
	z-index: 9;
	display: flex;
	flex-direction: column;
	align-items: end;
}
.affix_btn {
	width: 44px;
	height: 44px;
	box-shadow: 0px 1px 5px 0px rgba(153, 153, 153, 0.45);
	border-radius: 4px;
	margin-bottom: 10px;
	position: relative;
	background: #588bff;
	&:hover {
		background: #fff;
		transition: background 0.3s, color 0.3s;
		-webkit-transition: background 0.3s, color 0.3s;
	}
}

.people-icon {
	width: 72px;
	height: 113px;
	margin-top: 13px;
	cursor: pointer;
}

.baize-icon {
	width: 50px;
	height: 50px;
	cursor: pointer;
}
@media screen and (min-width: 300px) and (max-width: 750px) {
	.affix-area {
		display: none;
	}
}
</style>
<style lang="scss">
.el-popover.right-popover {
	background: #588bff !important;
	color: #fff !important;
	border: 1px solid #588bff !important;
	padding: 0 !important;
}
.el-popover.transparent-popover {
	background: transparent !important;
	border: 1px solid transparent !important;
	padding: 0 !important;
	box-shadow: none !important;
	min-width: 144px !important;
}

.chat-people.vdr-container.dragging,
.chat-people.vdr-container.active {
	border: none !important;
}
</style>
