import { computed } from 'vue';
import { list } from './chatLimitList';
const eventIds = ['f4afc253-546e-669d-aba0-a8bb49035132', '22d645dd-42f9-d6ab-2482-beedc3d9b595', 'eb447f51-ed53-16b3-63a0-b8e103612023'];
const state = {
	token: '',
	refreshToken: '',
	userInfo: {},
	loginStatus: false,
	code: '',
	appId: '',
	courseDetail: {
		showRecentViewNumCondition: 100, //共同观看人数显示‘最近’的条件
		tabIndex: 0,
	},
	//触发问答弹窗logo点击次数
	logoClickNum: 0,
	baseData: {},
	baseDataStatus: false,
	myCourse: [],
	myCourseStatus: false,
	chatLimitCourseIdList: list,
	chatShow: false,
	currentCourseId: '', //当前点击微课详情页的课程id
	currentModuleId: '', //当前点击微课详情页的课程ModuleId
	passportUserInfo: {},
	passportStatus: false,
	JtiStatus: false, //小程序传过来的登录状态
	baizeUrl: 'https://le.ouchn.cn/Event/cd507def-90bd-5da6-4483-5eff4eb46bc0',
	ziyuanClassifySelectPath: [],
	ziyuanClassifySelectId: '',
	traceRecommendCourseParams: {}, //追踪推荐课程的参数
	// 针对特定活动增加背景色
	eventBackgroundMap: new Map([
		['f4afc253-546e-669d-aba0-a8bb49035132', 'gradient-event-background'],
		['22d645dd-42f9-d6ab-2482-beedc3d9b595', 'gradient-event-background'],
		['eb447f51-ed53-16b3-63a0-b8e103612023', 'gradient-event-background2'],
	]),
	// 全屏的保利威视频活动页id
	fullPolyvEvent: computed(() => eventIds.some((id) => location.href.includes(id))),
};

export { state };
